<template>
  <div :class="{'p-6': hasBackofficeAccess}">
    <div class="vx-row" v-if="hasBackofficeAccess">
      <div class="vx-col w-full mb-3">
        <router-link class="decorated" :to="`/project/${id}`"
          >Regresar al proyecto
        </router-link>
      </div>
    </div>

    <!-- AGREGAR INFORMACIÓN ADICIONAL DE FUENTE DE INGRESOS  -->
    <template v-if="!isEditingIncome">
      <big-title v-if="isMounted">
        {{project.alias || "Pendiente" }} -<span> {{id}}.</span>
        <h5 class="mt-2">Fuente de ingresos - {{ppIncomeSourceName}}</h5>
        <p class="text-subtitle mt-6">Agrega información de adicional y comprobantes de ingresos.</p>
      </big-title>
      <!-- SECCIONES -->
      <div v-if="isMounted && !failed" >
        <template v-for="section in allSections">
          <vx-card :key="section.id" :title="section.public_name" class="mb-5">
            <p class="mb-4 bold" v-if="section.public_description != null">{{section.public_description}}</p>

            <div class="vx-row">
              <div class="vx-col w-full mb-6" v-if="section.id == 127 && locationDefined">
                <p><strong>Dirección de lugar de trabajo:<br></strong> {{fullLocation}}</p>
                <vs-divider></vs-divider>
              </div >
              <template v-for="f in section.fields">
                <!-- Campos automaticos -->
                <form-field
                  :ref="'section' + section.id"
                  v-if="isAutoFormField(f)"
                  :key="f.id"
                  :f="f"
                  :dataContainer="payload"
                  :keepDisabled="isDisabled"
                  :collectionsRoot="collections"
                  :blockedByDocument="f.blockedByDocument"
                  :onChangeReceptor="onFieldChange"
                  :countriesList="collections.countries"
                  :evaluator="evaluateCondition" />
                
                <income-proofs-control
                  :key="f.id"
                  :f="f"
                  :isDisabled="false"
                  :blockedByDocument="f.blockedByDocument"
                  :pp_income_source_id="income_source_id"
                  :person-type="client_person_type"
                  :userId="project.client.user.id"
                  :project="project"
                  :isMoral="isMoral"
                  v-else-if="f.fname == 'pp_ic_proofs'"
                  :role="role"
                  ref="pp_ic_proofs"/>

              </template>

            </div>

            <!-- Save Button -->
            <template v-if="role == 'pf_income_source' || role == 'pf_income_source_obligated' || role == 'pf_income_source_representative' || role == 'pf_income_source_owner'">
              <div class="vx-row" v-if="(section.id == 127) && !isDisabled">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveIncomeSourceData(section)">Guardar cambios</vs-button>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="vx-row">
                <div class="vx-col w-full">
                  <div class="flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveIncomeSourceData(section)">Guardar cambios</vs-button>
                  </div>
                </div>
              </div>
            </template>
          </vx-card>
        </template>


      </div>
    </template>
    <!-- AGREGAR INFORMACIÓN ADICIONAL DE FUENTE DE INGRESOS  -->

    <!-- AGREGAR FUENTE DE INGRESOS -->
    <template v-else>
      <big-title v-if="isMounted">
        {{project.alias || "Pendiente" }} -<span> {{id}}.</span>
        <h5 class="mt-2">Indica el tipo de fuente de ingresos del solicitante.</h5>
        <p class="text-subtitle mt-6">Considera que como negocio propio podrás subir como comprobante de ingresos los <strong>gastos operativos</strong> del mismo. ¡RedGirasol es el crédito más flexible!</p>
      </big-title>

      <vx-card class="mb-5">
        <div class="con-exemple-prompt">
        <vs-alert v-if="onError" class="h-full mb-5" color="danger">
          <span>{{ errorMssg }}</span>
        </vs-alert>
        <vs-alert v-if="onSuccess" class="h-full mb-5" color="success">
          <span>{{ successMssg }}</span>
        </vs-alert>

        <label for="" class="vs-input--label"
          >Fuente de ingresos</label
        >
        <v-select
          name="family_group_id"
          label="text"
          class="vs-custom w-full mb-5"
          v-model.lazy="selectedIncomeSourceId"
          :options="getIncomeSourcesForSelect"
          :reduce="(text) => text.value"
          :clearable="false"
          :searchable="false"
          placeholder="Seleccione una opción"
        >
          <div slot="no-options">No hay coincidencias</div>
        </v-select>
        <v-error
          v-if="hasError('selectedIncomeSourceId')"
          :error="errorText('selectedIncomeSourceId')"
          :success="isSuccess('selectedIncomeSourceId')"
        />

        <vs-button
          :disabled="!completeForm || $isLoading"
          @click="addIncomeSourceToApplicant"
          color="primary"
          class="mr-5 mt-2"
          >Agregar</vs-button
        >
      </div>

      </vx-card>
    </template>
    <!-- AGREGAR FUENTE DE INGRESOS -->

    <load-error v-if="failed" />
  </div>
</template>

<script>
import inputHelper from '@components/mixins/inputHelper';
import dateHelper from '@components/mixins/dateHelper';
import formHelper from '@components/mixins/formHelper';
import docsHelper from '@components/mixins/docsHelper';
import FormField from '@components/forms/FormField.vue';
import IncomeProofsControl from '@incomeSources/IncomeProofsControl'

const incomeSourcesSectionsPFAE = [127,128];
const incomeSourcesSectionsPM = [129, 130];
const verifiedEnumValue = "verificado";
const errorMssgDef = "Ha ocurrido un error, intente más tarde.";
const successMssgDef = "El registro ha sido agregado exitosamente";
const roles = ["pf_income_source", "pf_income_source_obligated", "pm_income_source", "pm_income_source_obligated", "pm_income_source_representative", "pf_income_source_representative", "pf_income_source_owner"];

const requiredObjects = [
  'client.user.personal',
  'client.user.personal.address',
  'client.user.personal.creditProfile',
  'client.user.personal.PPIncomeSources',
  'client.user.personal.PPIncomeSources.phone',
  'client.user.personal.PPIncomeSources.address',
  'client.user.personal.PPIncomeSources.incomeSources',

  'client.user.business.personal',
  'client.user.business.personal.address',
  'client.user.business.personal.creditProfile',
  'client.user.business.personal.PPIncomeSources',
  'client.user.business.personal.PPIncomeSources.phone',
  'client.user.business.personal.PPIncomeSources.address',
  'client.user.business.personal.PPIncomeSources.incomeSources',

  'client.user.business.ownerPersonalProfile',
  'client.user.business.ownerPersonalProfile.address',
  'client.user.business.ownerPersonalProfile.creditProfile',
  'client.user.business.ownerPersonalProfile.PPIncomeSources',
  'client.user.business.ownerPersonalProfile.PPIncomeSources.phone',
  'client.user.business.ownerPersonalProfile.PPIncomeSources.address',
  'client.user.business.ownerPersonalProfile.PPIncomeSources.incomeSources',

  'finance.guarantee.guaranteeProfile.personal',
  'finance.guarantee.guaranteeProfile.personal.address',
  'finance.guarantee.guaranteeProfile.personal.creditProfile',
  'finance.guarantee.guaranteeProfile.personal.PPIncomeSources',
  'finance.guarantee.guaranteeProfile.personal.PPIncomeSources.phone',
  'finance.guarantee.guaranteeProfile.personal.PPIncomeSources.address',
  'finance.guarantee.guaranteeProfile.personal.PPIncomeSources.incomeSources',

  'finance.guarantee.guaranteeProfile.business',
  'finance.guarantee.guaranteeProfile.business.address',
  'finance.guarantee.guaranteeProfile.business.creditProfile',
  'finance.guarantee.guaranteeProfile.business.BPIncomeSources.incomeSources',

  'client.user.business.ownerBusinessProfile',
  'client.user.business.ownerBusinessProfile.address',
  'client.user.business.ownerBusinessProfile.creditProfile',
  'client.user.business.ownerBusinessProfile.BPIncomeSources.incomeSources',

  'client.user.business',
  'client.user.business.address',
  'client.user.business.creditProfile',
  'client.user.business.BPIncomeSources.incomeSources',
];

export default {
    props: ['id', 'income_source_id', 'role'],
    mixins: [formHelper, inputHelper, dateHelper, docsHelper],
    data(){
      return {
          isEditingIncome: false,
          selectedIncomeSourceId: null,
          getIncomeSourcesForSelect: [],
          onError: false,
          onSuccess: false,
          errorMssg: errorMssgDef,
          successMssg: successMssgDef,

          project: {},
          form_id: 12,

          form: {},
          allSections: [],
          documentsFields: [],
          documentsStates: [],
          validatedFields: [],
          validatedDocuments: [],
          allDocumentFields: [],

          loading: false,
          isMounted: false,
          failed: false,

          payload: {},

          collections: {
            countries: [],
            states: [],
            business_activities: [],
            income_sources: [],
            income_document_collection: [], 
            employer_cities: [],

            states_income_sources_address: [],
            cities_income_sources_address: [],
            neighborhoods_income_sources_address: []
          },

          ppIncomeSource: {},
          ppIncomeSourceName: "",
          ppIncomeSourceId: null,
          cpAddressSource: null,
          clientAddress: null,
          clientAddressId: null,
          
          locationDefined: false,
          employerAddress: null,
          fullLocation: null,

          // auxiliares para evaluacion de condiciones
          mexicoCountryId: 700,
          incomeSourcesSectionsPFAE: incomeSourcesSectionsPFAE,
          incomeSourcesSectionsPM: incomeSourcesSectionsPM,

          isMoral: false,
          isPfae: false,
          pmClientType: 0,
          pfClientType: 1,
          profileId: null,

          // BASE INFORMATION
          personal_profile_id: null,
          roles: roles
        }
    },
    components: {
        FormField
        , IncomeProofsControl
    },
    async mounted() {
      this.showLoading(true);
      this.loading = true;
      this.isMounted = false;
      await this.getProjectInfo();
      this.setBaseInformation();
      if(this.income_source_id != -1){
        await this.getCollections();
        await this.getFormInfo();
        await this.getDocumentCollections();
        this.isMoral = this.client_person_type == this.pmClientType;
        this.isPfae = this.client_person_type == this.pfClientType;
      } else {
        await this.getIncomeSources();
        this.isEditingIncome = true;
      }
      this.isMounted = true;
      this.loading = false;
      this.showLoading(false);
    },
    computed: {
      ownerPersonalProfile() {
        return this.project.client.user.business.owner_personal_profile;
      },
      ownerBusinessProfile() {
        return this.project.client.user.business.owner_business_profile;
      },
      financeGuaranteeProfile() {
        const guaranteeType = this.getSavedGuaranteeType();
        if(guaranteeType == 1)
          return this.project.finance.guarantee.guarantee_profile.personal;
        if(guaranteeType == 2)
          return this.project.finance.guarantee.guarantee_profile.business;
      },
      hasBackofficeAccess(){
        return this.$acl.check('isBackoffice');
      },
      isDisabled(){
        return this.isArchived || this.project.status >= 4 || this.hasBackofficeAccess;
      },
      docsDisabled(){
        return this.isArchived || this.project.status >= 4;
      },
      personal(){
        return this.project.client.user.personal;
      },
      pf_guarantee_personal(){
        // return this.project.client.user.personal.guarantee_profile.personal;
        return this.project.finance.guarantee.guarantee_profile.personal;
      },
      client_person_type(){
        switch(this.role) {
          case 'pf_income_source':
          case 'pf_income_source_obligated':
          case 'pf_income_source_representative':
          case 'pf_income_source_owner':
            return 1;
          case 'pm_income_source':
          case 'pm_income_source_obligated':
          case 'pm_income_source_representative':
          case 'pm_income_source_owner':
            return 0;
        }
      },
      ppIncomeSourceComputed(){
        return this.project.client.user.personal.p_p_income_sources.find(item => item.id == this.income_source_id);
      },
      client_should_be_working_or_own_a_business(){
        return (this.ppIncomeSource.income_source_id == 1 || this.ppIncomeSource.income_source_id == 2);
      },
      client_should_be_working_or_own_a_business_and_not_the_same_address(){
        return (this.client_should_be_working_or_own_a_business && this.cpAddressSource == 2);
      },
      client_should_be_working_or_own_a_business_and_not_the_same_address_and_mexican_address() {
        return (this.client_should_be_working_or_own_a_business 
              && this.cpAddressSource == 2 
              && this.payload.address$country_id == this.mexicoCountryId)? true : false;
      },
      client_should_be_working_or_own_a_business_and_not_the_same_address_and_foreign_address() {
        return (this.client_should_be_working_or_own_a_business 
              && this.cpAddressSource == 2 
              && this.payload.address$country_id != this.mexicoCountryId) ? true : false;
      },
      completeForm(){
        return this.selectedIncomeSourceId != null;
      },
    },
    watch: {
      loading : function(newVal, oldVal){
        if(newVal == true){
          this.$vs.loading();
        }
        else {
          this.$vs.loading.close();
        }
      },
    },
    methods: {
      getSavedGuaranteeType() {
        if(this.project.finance.guarantee && this.project.finance.guarantee.guarantee_profile) {
          const guaranteeProfile = this.project.finance.guarantee.guarantee_profile;
          if(guaranteeProfile.personal)
            return 1;
          if(guaranteeProfile.business)
            return 2;
        }
      },
      async getProjectInfo() {
        try {
          this.onError = false;
          let params = `with[]=${requiredObjects.join("&with[]=")}`;
          let response = await axios.get(`/api/v2/projects/${this.id}?${params}`);
          if(!response.data){
            this.abort();
            return;
          }

          this.project = response.data;

          if(this.income_source_id != -1){ //SE VALIDA SI YA SE DEFINIÓ UNA FUENTE DE INGRESO.
            if(this.role === "pf_income_source") {
              if(!this.project.client.user.personal.p_p_income_sources){
                this.abort();
                return;
              }
              this.clientAddressId = this.nestedFieldValue(this.project, 'client.user.personal.address.id');
              this.clientAddress = this.nestedFieldValue(this.project, 'client.user.personal.address');

              this.ppIncomeSource = this.project.client.user.personal.p_p_income_sources.find(item => item.id == this.income_source_id);
            }
            else if(this.role === "pf_income_source_representative") {
              if(!this.project.client.user.business.personal.p_p_income_sources){
                this.abort();
                return;
              }
              this.clientAddressId = this.nestedFieldValue(this.project, 'client.user.business.personal.address.id');
              this.clientAddress = this.nestedFieldValue(this.project, 'client.user.business.personal.address');

              this.ppIncomeSource = this.project.client.user.business.personal.p_p_income_sources.find(item => item.id == this.income_source_id);
            }
            else if(this.role === "pf_income_source_obligated") {
              if(!this.financeGuaranteeProfile.p_p_income_sources){
                this.abort();
                return;
              }
              this.clientAddressId = this.nestedFieldValue(this.project, 'finance.guarantee.guarantee_profile.personal.address.id');
              this.clientAddress = this.nestedFieldValue(this.project, 'finance.guarantee.guarantee_profile.personal.address');
              
              this.ppIncomeSource = this.financeGuaranteeProfile.p_p_income_sources.find(item => item.id == this.income_source_id);
            }
            else if(this.role === "pm_income_source_obligated") {
              if(!this.financeGuaranteeProfile.b_p_income_sources){
                this.abort();
                return;
              }
              this.ppIncomeSource = this.financeGuaranteeProfile.b_p_income_sources.find(item => item.id == this.income_source_id);
            }
            else if(this.role === "pf_income_source_owner") {
              if(!this.ownerPersonalProfile.p_p_income_sources){
                this.abort();
                return;
              }
              this.clientAddressId = this.nestedFieldValue(this.project, 'client.user.business.owner_personal_profile.address.id');
              this.clientAddress = this.nestedFieldValue(this.project, 'client.user.business.owner_personal_profile.address');

              this.ppIncomeSource = this.ownerPersonalProfile.p_p_income_sources.find(item => item.id == this.income_source_id);
            }
            else if(this.role === "pm_income_source_owner") {
              if(!this.ownerBusinessProfile.b_p_income_sources){
                this.abort();
                return;
              }
              this.ppIncomeSource = this.ownerBusinessProfile.b_p_income_sources.find(item => item.id == this.income_source_id);
            }
            else if(this.role === "pm_income_source") {
              if(!this.project.client.user.business.b_p_income_sources){
                this.abort();
                return;
              }
              this.ppIncomeSource = this.project.client.user.business.b_p_income_sources.find(item => item.id == this.income_source_id);
            }
            else {
              this.abort();
              return;
            }

            if(!this.ppIncomeSource){
              this.abort();
              return;
            }

            this.ppIncomeSourceName = this.ppIncomeSource.income_sources.name;
            this.ppIncomeSourceId = this.ppIncomeSource.income_source_id;
            
            switch(this.role) {
              case 'pf_income_source':
                this.project.client.user.personal.p_p_income_sources = this.ppIncomeSource; 
              case 'pf_income_source_obligated':
              case 'pf_income_source_representative':
              case 'pf_income_source_owner':
                if(this.clientAddressId == this.ppIncomeSource.employer_address_id){
                  this.payload.select_address_source = 1;
                  this.cpAddressSource = 1; 
                  this.locationDefined = true;
                  this.fullLocation = this.clientAddress.full_address;
                } else {
                  this.payload.select_address_source = 2;
                  this.cpAddressSource = 2; 
                }

                this.setPayloadInformation(this.ppIncomeSource, this.cpAddressSource);
                this.reload_cities();
                break;
              case 'pm_income_source':
              case 'pm_income_source_obligated':
              case 'pm_income_source_owner':
              case 'pm_income_source_representative':
                this.setMoralPayloadInformation(this.ppIncomeSource);
                break;
            }
          }
        }
        catch (error) {
          console.log(error);
          this.processError(error.status);
        }
      },
      async getCollections(){
        let collectionsObjects = ['countriesList', 'statesList', 'businessActivities', 'incomeSources'];

        let params = "with[]=" + collectionsObjects.join("&with[]=");
        let res = await axios.get(`/api/v1/forms/getFormCollections?${params}`);
        this.collections.countries = res.data.countriesList;
        this.collections.states = res.data.statesList;
        this.collections.business_activities = res.data.businessActivities;
        this.collections.income_sources = this.get_allowed_income_sources(res.data.incomeSources);
      },
      async getFormInfo(){
        switch(this.role) {
          case 'pf_income_source':
          case 'pf_income_source_obligated':
          case 'pf_income_source_representative':
          case 'pf_income_source_owner':
            this.form_id = 12;
            break;
          case 'pm_income_source':
          case 'pm_income_source_obligated':
          case 'pm_income_source_representative':
          case 'pm_income_source_owner':
            this.form_id = 16;
            break;
        }

        try{
          let response = await axios.get("/api/v1/forms/getFormFields/" + this.form_id);
          this.form = response.data.form;
          this.allSections = this.getIncomeSourceSection(response.data.sections);
          let url = this.ApiDomain + `/storagev3/documents/form/${this.form_id}`;
          let response2 = await axios.get(url);
          this.documentsStates = response2.data;

          this.allDocumentFields = [];

          await this.asyncForEach(this.allSections, async (s) => {
            s.documentFields = [];
            await this.asyncForEach(s.fields, async (f) => {
              this.formFieldSetter(f, this, true);

              // // check for documents
              // if(f.doc_id != null){
              //   let ff = {formField: f, documentSet: null, target_id: null };
              //   let ss = this.documentsStates.find(sid => sid.id == s.id);
              //   if(ss && ss.documents){
              //     let dset = ss.documents.find(d => d.id == f.doc_id);
              //     if(dset){
              //       ff.target_id = this.docTargetEvaluator(dset.clasification, dset.clasification_2);
              //       ff.documentSet = dset;
              //     }
              //   }
              //   s.documentFields.push(ff);
              //   this.allDocumentFields.push(ff);
              // }
            });
          });
          let res = await this.getAllDocumentsStates(this.allDocumentFields, this.evaluateCondition);
          if(res){
            this.allDocumentFields.forEach(f => {
              if(f.loadedState && f.loadedState.is_verified == verifiedEnumValue){
                this.validatedDocuments.push(f.documentSet.id);
              }
            })
          }
        }
        catch(e){
          console.log(e);
          this.failed = true;
        }
      },
      onDocumentUploadSuccess(data){
        let d = this.allDocumentFields.find(f => f.documentSet.id == data.document_id);
        d.loadedState = data;
      },
      setBlockedDocuments(){
        this.validatedDocuments.forEach(doc => {
          this.allSections.forEach(section => {
            section.fields.filter(f => f.block_by_document_id == doc).forEach(fd => fd.blockedByDocument = true);
          })
        })
      },
      abort(){
        this.loading = false;
        this.$router.replace({name: 'inicio'});
      },
      async onFieldChange(method){
        if(method != null && method in this){
          this[method]();
        }
      },
      evaluateCondition(condition){
        return this.evaluateConditionBase(this, condition);
      },
      getIncomeSourceSection(sections){
        let result = [];

        switch(this.role) {
          case 'pf_income_source':
          case 'pf_income_source_obligated':
          case 'pf_income_source_representative':
          case 'pf_income_source_owner':
            sections.forEach(s => {
              if(this.incomeSourcesSectionsPFAE.some(x => x == s.id)){
                result.push(s);
              }
            });
            break;
          case 'pm_income_source':
          case 'pm_income_source_obligated':
          case 'pm_income_source_representative':
          case 'pm_income_source_owner':
            sections.forEach(section => {
              if(this.incomeSourcesSectionsPM.some(allowedSectionId => allowedSectionId == section.id)) {
                result.push(section);
              }
            });
            break;
        }
        return result;
      },
      async reload_cities(){
        try {
          let res = await axios.get('/api/register/cities/' + this.payload.address$state_id);
          this.collections.employer_cities = res.data;
        } catch (e) {
          this.warn(e);
        }
      },
      async getDocumentCollections() {
        try {
          let res = await axios.get(
            `/api/v1/income_proofs/${this.ppIncomeSourceId}/get_document_collection`
          );
          this.collections.income_document_collection = res.data;
        } catch (e) {
          this.warn(e);
        }
      },
      get_allowed_income_sources(collection){
        let incomeSources = collection;
        let response  = [];

        incomeSources.forEach(is => {
          let person_types = JSON.parse(is.person_type);
          if(person_types.allowed.some( x => x == this.client_person_type)){
            response.push(is);
          }
        });

        return response;
      },
      update_cp_address_source(){
        this.cpAddressSource = this.payload.select_address_source;
      },
      async saveIncomeSourceData(section) {
        let result = await this.runValidations(section);
        if(!result){
          this.missingFieldsNotif();
        }
        else{
          this.loading = true;
          let res = await this.saveFormInfo(section);
          this.loading = false;

          if(res == true){
            this.saveSuccessNotif();
          }
          else {
            this.failedOperationNotif(null, null);
          }
        }
      },
      async runValidations(section) {
        let gslug = "section" + section.id;

        let res1 = await this.validateFormFields(gslug);
        if(!res1){
          return false;
        }
        return true;
      },
      async validateFormFields(refGroup){
        let allValid = true;
        this.validatedFields = [];
        let grefs = this.$refs[refGroup];
        let f;
        for(let p in grefs){
          f = grefs[p];
          let r = await f.checkForValidDirty();
          if(r.valid == false){
            allValid = false;
          }
          else if(r.dirty == true) {
            let sp = f.specialValidation();
            if(sp != null && sp.method in this){
              let res = await this[sp.method]();
              let n = f.rawFieldName;
              if(!res){
                allValid = false;
                f.setError(sp.error);
                this.errorNotif(n, sp.error, 10000);
                continue;
              }
            }
            this.validatedFields.push(r);
          }
        }
        return allValid;
      },
      async saveFormInfo(section){
        let ok = false;
        try{
          if(this.client_should_be_working_or_own_a_business){
            if(this.cpAddressSource == 1){
              this.payload.employer_address_id = this.clientAddressId;
              this.payload.address = null;
            }
          } else {
            this.payload.employer_address_id = null;
              this.payload.address = null;
          }

          let payload = this.packNested(this.payload);
          await axios.post(`/api/v1/income_sources/${this.income_source_id}/${this.role}/update_income_source`, payload);
          ok = true;
          await this.getProjectInfo();
        }
        catch(e){
          this.warn(e);
          ok = false;
        }
        return ok;
      },
      setPayloadInformation(incomeSource, cpAddressSource){
        this.payload = {
          employer : incomeSource.employer
          , employer_activity_id : incomeSource.employer_activity_id
          , employer_address_id : incomeSource.employer_address_id
          , employer_phone : incomeSource.employer_phone
          , employer_phone_ext : incomeSource.employer_phone_ext
          , id : incomeSource.id
          , income_source_id : incomeSource.income_source_id
          , senority : incomeSource.senority
          , income_source_document_id: incomeSource.income_source_document_id
          , select_address_source: cpAddressSource
          , phone: incomeSource.phone ? incomeSource.phone.phone : null
          , phone_ext: incomeSource.phone ? incomeSource.phone.phone_ext : null
          , country_calling_code: incomeSource.phone ? incomeSource.phone.country_calling_code : null
        }
        
        if(this.cpAddressSource != 1 && incomeSource.address){
          this.payload.address$id = incomeSource.address.id;
          this.payload.address$city_id = incomeSource.address.city_id;
          this.$set(this.payload, 'address$country_id', incomeSource.address.country_id);
          this.payload.address$ext_number = incomeSource.address.ext_number;
          this.payload.address$int_number = incomeSource.address.int_number;
          this.payload.address$municipality = incomeSource.address.municipality;
          this.payload.address$neighborhood = incomeSource.address.neighborhood;
          this.payload.address$neighborhood_id = incomeSource.address.neighborhood_id;
          this.payload.address$foreign_zipcode = incomeSource.address.foreign_zipcode;
          this.payload.address$foreign_neighborhood = incomeSource.address.foreign_neighborhood;
          this.payload.address$other_city = incomeSource.address.other_city;
          this.payload.address$other_state = incomeSource.address.other_state;
          this.payload.address$street = incomeSource.address.street;

          if(incomeSource.address.neighborhood_model){
            this.payload.address$zipcode = incomeSource.address.neighborhood_model.zipcode;
            this.payload.address$city_id = incomeSource.address.neighborhood_model.city_id;
            this.payload.address$state_id = incomeSource.address.neighborhood_model.state_id;
          } else {
            this.payload.address$state_id = "";
            this.payload.address$zipcode = "";
            this.payload.address$city_id = "";
          }
        }

        this.reload_cities();
      },
      setMoralPayloadInformation(incomeSource) {
        this.payload = {
          id: incomeSource.id,
          income_source_document_id: incomeSource.income_source_document_id,
          income_source_id : incomeSource.income_source_id
        };
      },
      hasError(val){
        return this.errors.has(val);
      },
      errorText(val){
        return this.errors.first(val);
      },
      isSuccess(val){
        let ff = this.fields[val];
        return (ff && ff.required && ff.valid);
      },
      async getIncomeSources(){
        try{
          let res = await axios.get(`/api/v1/forms/${this.client_person_type}/getIncomeSources`);
          let sources = res.data;

          let options = [];

          sources.forEach(s => {
            if(this.income_source_id == -1 && s.id == 19)
              return;
            options.push({value: s.id, text: s.name});
          });
          this.getIncomeSourcesForSelect = options;
        } catch (error) {

        }
      },
      async addIncomeSourceToApplicant(){
        // v1/forms/new/income_source
        this.onSuccess = false;
        this.onError = false;

        this.showLoading(true);
        try {
          const payload = {
            profileId: this.profileId,
            incomeSourceId: this.selectedIncomeSourceId,
            roleType: this.role
          };
          let res = await axios.post(`/api/v1/forms/createIncomeSource`, payload);
          let new_pp_income_id;

          if(res.data.success != true){
            this.errorMssg = res.data.error;
            this.onError = true;
          }
          else {
            new_pp_income_id = res.data.id;
            this.onSuccess = true;

            // redireccionar a la vista de edicion
            const newUrl = window.location.href
            location.href = newUrl.replace(-1, new_pp_income_id);
          }
        }
        catch (error) {
          this.warn(error);
          this.onError = true;
        }
        this.showLoading(false);
      },
      setBaseInformation(){
        switch(this.role){
          case "pf_income_source":
            this.profileId = this.personal.id;
            break;
          case "pf_income_source_obligated":
            this.profileId = this.project.finance.guarantee.guarantee_profile.personal.id;
            break;
          case "pf_income_source_representative":
            this.profileId = this.project.client.user.business.personal.id;
            break;
          case "pf_income_source_owner":
            this.profileId = this.project.client.user.business.owner_personal_profile.id;
            break;
          case "pm_income_source_obligated":
            this.profileId = this.project.finance.guarantee.guarantee_profile.business.id;
            break;
          case "pm_income_source_owner":
            this.profileId = this.project.client.user.business.owner_business_profile.id;
            break;
          case "pm_income_source":
            this.profileId = this.project.client.user.business.id;
            break;
          default: console.log("NO SE DEFINIÓ");
        }
      },
      verify_zip_code_income_sources_address() {
        this.verifyPayloadZipCode(this.payload, {
          addressName: 'address',
          statesArray: 'states_income_sources_address', 
          citiesArray: 'cities_income_sources_address', 
          neighborhoodsArray: 'neighborhoods_income_sources_address',
          addressId: this.payload.address$id
        });
      },
      set_zip_code_values_income_sources_address() {
        this.setPayloadZipCodeAddressValues(this.payload.address$id, this.payload, {
          addressName: 'address',
          statesArray: 'states_income_sources_address', 
          citiesArray: 'cities_income_sources_address', 
          neighborhoodsArray: 'neighborhoods_income_sources_address'
        });
      }
    }
};
</script>

<style>
</style>