<template>
  <div class="px-2 py-2">
    <div class="vx-row mb-3">
        <div class="vx-col sm:w-2/3 w-full">
            <h4 class="mb-3">Detalle del comprobante de ingresos.</h4>
            <ul>
                <li>
                    Tipo: <strong>{{incomeProof.document_type}}</strong>
                </li>
                <li>
                    Fecha: <strong>{{incomeSpecial(incomeProof.date)}}</strong>
                </li>
            </ul>
        </div>
        <div v-if="incomeProof.proof_required != 1" class="vx-col sm:w-1/3 w-full">
          <div class="flex flex-wrap items-center justify-end">
            <vs-button  color="danger" icon="delete" vs-justify="flex-end" @click="removeProof()"></vs-button>
          </div>
        </div>
    </div>

    <div class="vx-row px-4">
      <vs-alert v-if="errorMssg" icon-pack="feather" style="height:auto" icon="icon-alert-triangle" class="mb-3" color="danger">
        <span>{{errorMssg}}</span>
      </vs-alert>

      <vs-alert v-if="successMssg" icon-pack="feather" style="height:auto" icon="icon-check" class="mb-3" color="success">
        <span>{{successMssg}}</span>
      </vs-alert>
    </div>

    <!-- Formulario -->
    <div class="vx-row mt-2" v-if="isMounted">
      <template v-for="f in mfields">
        <!-- Campos automaticos -->
        <form-field
          ref="mfields"
          v-if="isAutoFormField(f)"
          :key="f.id"
          :f="f"
          :dataContainer="getContainer(f)"
          :collectionsRoot="collections"
          :keep-disabled="disableEdition"
          :onChangeReceptor="onFieldChange"
          :hasBackofficeAccess="hasBackofficeAccess"
          :blockedByDocument="f.blockedByDocument"
          :evaluator="evaluateCondition"
          :input-class-large="true"
          :editableByBackoffice="true" />
      </template>
      <div class="vx-col sm:w-1/2 w-full flex">
        <div class="flex flex-wrap items-center justify-start">
          <vs-button class="ml-2" @click.stop="saveChangesRequest">Guardar</vs-button>
        </div>
      </div>
    </div>

    <div class="vx-row" v-if="isMounted">
      <document-upload
        :target_id="targetId"
        :f="doc.formField"
        :doc="doc.documentSet"
        :evaluator="evaluateCondition"
        :loadedState="doc.loadedState"
        :disabled="disableEdition"
        :stickToLoadedState="true"
        @on-success="onDocumentUploadSuccess"
        :editableByBackoffice="true"
        :inputClassExtraLarge="true"
        :from_project_id="currentProjectID"
      />
    </div>
  </div>
</template>

<script>
import FormField from '@components/forms/FormField.vue'
import inputHelper from '@components/mixins/inputHelper';
import formHelper from '@components/mixins/formHelper';
import dateHelper from '@mixins/dateHelper';

export default {
  name: "FormIncomeProofs",
  props: {
    incomeProof: { type: Object },
    collections: { type: Object, required: true },
    onEditMode: { type: Boolean, default: false },
    disableEdition: { type: Boolean, default: false },
    isMoral: { type: Boolean, required: true },
    userId: { type: [String,Number], required: true },
    personType: { type: [String,Number], required: true },
    sectionInfo: { type: Object, required: true },
    docInfo: { type: Object, required: true },
    hasBackofficeAccess: { type: Boolean, required: true },
    userData: { type: Object, required: true },
    project: {type: Object, default: null},
    role: { type: String, default: "pf_income_source"}
  },
  mixins: [formHelper, inputHelper, dateHelper],
  components:{
    FormField
  },
  data(){
    return {
      isMounted: false,
      mexicoCountryId: 700,
      pmDocId: 134,
      pfaeDocId: 133,
      pfaeOsDocId: 136,
      mfields: [],
      base: {},
      bank: {},
      muser: {},
      mpersonType: null,
      address_is_mexico: false,
      targetId: null,
      doc: {},
      errorMssg: null,
      successMssg: null,
      //getters
      should_ask_for_bank_confirmation: false,

      showSaveBtn: true,
      saveBtnText: this.onEditMode ? "Guardar" : "Agregar",
      showFinishBtn: false,
      showCancelBtn: this.onEditMode,
      cancelBtnText: "Cancelar",

      currentDocFileId: null,
      docLoadedState: null,
    }
  },
  async beforeMount() {
    this.isMounted = false
    this.setUserProps();
    this.setFormFields();
    this.setDocField();
    await this.getDocState();
    this.isMounted = true
  },
  methods: {
    setFormFields(){
      this.mfields = this.sectionInfo.fields;
      this.mfields.forEach(f => {
        this.formFieldSetter(f, this);
      });
    },
    setUserProps(){
      this.mpersonType = parseInt(this.personType);
      this.targetId = this.userId;
      this.base = this.incomeProof;
      this.currentDocFileId = this.base.document_file_id;

      this.muser = this.userData.user;
    },
    async getDocState(){
      if(this.currentDocFileId === null){
        return;
      }
      this.showLoading(true)
      try {
        const res = await axios.get(`/storagev3/documents/files/getDocumentFile/${this.currentDocFileId}`);
        this.doc.loadedState = res.data;
      }
      catch (e) {
      }
      this.showLoading(false)
    },
    setDocField(){
      if(this.isMoral){
        let df = this.mfields.find(f => f.doc_id === this.pmDocId);
        this.doc = {formField: df, documentSet: this.docInfo, target_id: this.targetId, loadedState: null };
      }
      else {
        if(this.role === "pf_income_source"){
          let df = this.mfields.find(f => f.doc_id === this.pfaeDocId);
          this.doc = {formField: df, documentSet: this.docInfo, target_id: this.targetId, loadedState: null};
        } else {
          let df = this.mfields.find(f => f.doc_id === this.pfaeOsDocId);
          this.doc = {formField: df, documentSet: this.docInfo, target_id: this.targetId, loadedState: null};
        }
      }
    },

    getContainer(f){
      return this.base;
    },

    /* on change receptor */
    onFieldChange(method){
      if(method != null && method in this){
        this[method]();
      }
    },

    /* evaluator */
    evaluateCondition(condition){
      return this.evaluateConditionBase(this, condition);
    },

    async validate_personal_clabe_is_valid(){
      return this.validate_clabe(this.base.clabe, this.collections.banks);
    },
    async validate_business_clabe_is_valid(){
      return this.validate_clabe(this.base.clabe, this.collections.banks);
    },

    async onDocumentUploadSuccess(data){
      this.successMssg = "El documento ha sido cargado correctamente. El comprobante ahora se encuentra en proceso de verificación.";

      await this.$emit('on-account-saved');
    },

    async saveChangesRequest(){
      this.errorMssg = null;
      this.successMssg = null;

      this.showLoading(true);
      let res1 = await this.validateFormFields();
      if(!res1){
        this.errorMssg = "Verifique los datos faltantes o incorrectos."
        this.showLoading(false);
        return;
      }

      await this.doSaveChanges();
      this.showLoading(false);
    },
    extractBasePayload(){
      // unicamente obtener los datos actualizables en el form
      return (({amount}) => ({
        amount
      }))(this.base);
    },
    async doSaveChanges(){
      try {
        let payload = this.extractBasePayload();
        const res = await axios.post(`api/v1/income_proofs/${this.userId}/saveIncomeProofs/${this.incomeProof.id}/${this.role}`, payload);
        this.base = res.data.account;
        this.successMssg = "Comprobante actualizado de manera correcta.";
        await this.$emit('on-account-saved');
      }
      catch (e) {
        this.errorMssg = "Ha ocurrido un error guardando los datos. Intente más tarde.";
      }
    },
    async validateFormFields(){
      let allValid = true;
      let grefs = this.$refs.mfields;
      let f;
      for(let p in grefs){
        f = grefs[p];
        let r = await f.checkForValidDirty();
        if(r.valid === false){
          allValid = false;
        }
        else if(r.dirty === true) {
          let sp = f.specialValidation();
          if(sp != null && sp.method in this){
            let res = await this[sp.method]();
            let n = f.rawFieldName;
            if(!res){
              this.skipMissingWarnings = true;
              allValid = false;
              f.setError(sp.error);
              this.errorNotif(n, sp.error, 10000);
            }
          }
        }
      }
      return allValid;
    },
    async finish(){
      await this.$emit('on-finish');
    },
    async removeProof(){
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: "Eliminar comprobante",
        text: `¿Realmente desea eliminar el comprobante? Se removeran todos los registrasos asociados.`,
        acceptText: "Eliminar",
        cancelText: "Cancelar",
        accept: this.doRemoveItem,
      });
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {pp_income_proof_id: this.incomeProof.id, role: this.role};
        await axios.post(`/api/v1/income_proofs/remove_income_proof`, payload);
        await this.$emit('on-account-saved');
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
  },
  computed: {
    is_allowed(){
        return true;
    },
    pf_income_source_proof_exist(){
        return (this.base.id != null);
    },
    pp_income_source_proof_exist(){
      return true;
    },
    pp_income_source_proof_id(){
      return this.base.id;
    },
    currentProjectID(){
      return this.project.id;
    }
  },
}
</script>
