<template>
  <div v-if="isMounted" class="vx-col w-full mb-5">

    <!-- <vs-button color="primary" type="border" icon-pack="feather" class="mb-6 mt-3 mr-3" :disabled="hasBackofficeAccess" @click="addProofs()">Agregar comprobante</vs-button> -->

    <div class="vx-row">
      <div class="vx-col w-full">
        <vx-card class="mb-6">
          <h4 class="mb-3">Agregar nuevo periodo de comprobante de ingresos.</h4>
          <ul class="mb-6">
              <li>
                  Tipo: <strong>{{incomeProofs[0].document_type}}</strong>
              </li>
              <li>
                  Fecha: <strong>--</strong>
              </li>
          </ul>
          <div class="vx-row ">
            <div class="vx-col sm:w-1/2 w-full mb-4">
                <label for="" class="vs-input--label">Fecha de comprobante</label>
                <datepicker
                    placeholder="Fecha inicio"
                    :language="es"
                    v-model="startDate"
                    format="dd/MM/yyyy"
                    :disabledDates="disabledDates"
                    />
            </div>
            <div class="vx-col sm:w-1/2 w-full flex">
              <div class="flex flex-wrap items-center justify-start">
                <vs-button class="ml-2" @click.stop="saveIncomeProof()">Agregar</vs-button>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full flex" :key="index" v-for="(ip, index) in incomeProofs">
        <vx-card class="mb-6">
          <form-income-proofs
          :income-proof="ip"
          :on-edit-mode="onEditView"
          :is-moral="isMoral"
          :user-id="userId"
          :person-type="personType"
          :section-info="sectionInfo"
          :has-backoffice-access="hasBackofficeAccess"
          :user-data="project.client"
          :doc-info="docInfo"
          :disable-edition="formEditionDisabled"
          @on-account-saved="refreshProofs"
          @on-finish="onFinish"
          :collections="collections"
          :project="project" 
          :role="role"/>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import inputHelper from "@mixins/inputHelper";
import dateHelper from '@mixins/dateHelper';
import Datepicker from 'vuejs-datepicker';
import { es } from "vuejs-datepicker/dist/locale";
import {VMoney} from 'v-money';
import FormIncomeProofs from "@incomeSources/FormIncomeProofs";
const indicationDef = "Ingresa los datos solicitados.";
const errorMssgDef = "Ha ocurrido un error al agregar el comprobante, intente más tarde."
const successMssgDef = "El comprobante ha sido agregado exitosamente"


export default {
  name: "income-proofs-control",
  mixins: [inputHelper,dateHelper],
  props: {
    f: { type: Object, required: true },
    pp_income_source_id: { type: [Number, String], required: true },
    userId: { type: [String,Number], required: true },
    personType: { type: [String,Number], required: true },
    project: { type: Object, required: true },
    isMoral: { type: Boolean, required: true },
    role: { type: String, default: "pf_income_source"}
  },
  components: {
      Datepicker
      , FormIncomeProofs
  },
  directives: {money: VMoney},
  data() {
    return {
        es: es,
        isMounted: false,
        incomeProofs: {},
        onEditMode: false,
        onError: false,
        onSuccess: false,
        errorMssg: errorMssgDef,
        successMssg: successMssgDef,
        formPromptIndication: indicationDef,

        disabledDates: {
            from: new Date()
        },
        startDate: new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate()),
        endDate: new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate()),

        money: {
            decimal: '.',
            thousands: ',',
            prefix: '',
            suffix: '',
            precision: 2,
            masked: false
        },

        amount: 0,
        income_source_document: null,
        collections: {
          //TODO
        },
        pp_income_proof_id: null,
        pp_proof_selected : {},

        section_id_pm: 130,
        section_id_pfae: 128,
        section_id_pfae_os: 132,
        sectionInfo: null,
        docInfo: null,

        currentTitle: "",
        currentView: 0,
        currentUserAccount: null,
        formEditionDisabled: false,
    };
  },
  async mounted() {
    this.isMounted = false;
    await this.getIncomeProofs();
    // await this.getDocumentCollections();
    await this.getSectionFields();
    this.isMounted = true;
  },
  computed: {
    hasBackofficeAccess() {
      return this.$acl.check("isBackoffice");
    },
    is_allowed() {
      return false; //MEANWHILE
    },
    isPopupActive: {
      get() {
        return this.$store.state.isBankAccountsPopupActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_BANK_ACCOUNTS_POPUP_ACTIVE", val);
      }
    },
    onNewView(){
      return this.currentView === 0;
    },
    onEditView(){
      return this.currentView === 1;
    },
    get_current_sid(){
      switch(this.role){
        case "pf_income_source":
          return this.section_id_pfae;
        case "pf_income_source_obligated":
        case "pf_income_source_representative":
        case "pf_income_source_owner":
          return this.section_id_pfae_os;
        case 'pm_income_source':
        case 'pm_income_source_obligated':
        case "pm_income_source_representative":
        case "pm_income_source_owner":
          return this.section_id_pm;
        default: console.log("NO SE DEFINIÓ"); return null;
      }
    },
  },
  methods: {
    async getIncomeProofs() {
      try {
        let res = await axios.get(`/api/v1/incomeProofs/getProofs/${this.pp_income_source_id}/${this.role}`);
        this.incomeProofs = res.data;
      } catch (e) {
        this.warn(e);
      }
    },
    // async getDocumentCollections() {
    //   try {
    //     let res = await axios.get(
    //       `/api/v1/income_proofs/${this.pp_income_source_id}/get_document_collection`
    //     );
    //     this.collections.income_document_collection = res.data;
    //   } catch (e) {
    //     this.warn(e);
    //   }
    // },
    editItemRequest(item) {
    this.resetForm();
    this.setOnEditView();
    this.startDate = new Date(item.date);
    this.amount = this.currencyFormat(item.amount);
    this.income_source_document = item.income_source_document_id
    this.pp_income_proof_id = item.id
    this.onEditMode = true;
    this.isPopupActive = true;
    },
    removeItemRequest(item) {
      this.pp_proof_selected = item;

      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: "Eliminar producto",
        text: `¿Realmente desea eliminar el comprobante?`,
        acceptText: "Eliminar",
        cancelText: "Cancelar",
        accept: this.doRemoveItem,
      });
    },
    getDocumentName(document){
      if(document != null){
        let d = document.income_source_document_types;
        return d.name;
      } else  {
        return "--";
      }
    },
    resetForm(){
        this.onError = null
        this.onSuccess = null
        this.onEditMode = false
        this.startDate = new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate());
        this.amount = null;
        this.income_source_document = null;
    },
    addProofs(){
        this.resetForm();
        this.setOnNewView();
        this.isPopupActive = true;
    },
    closeFormPrompt() {
      this.isPopupActive = false;
      this.resetForm();
    },

    async saveIncomeProof(){
        await this.saveProof();
    },
    async saveProof(){
        this.onSuccess = false;
        this.onError = false;

        this.showLoading(true);
        try {
            let payload = { 
                pp_income_source_id: this.pp_income_source_id,
                startDate: this.startDate,
                role: this.role
            }; 
            await axios.post(`/api/v1/income_proofs/add_income_proof`, payload);
            this.onSuccess = true;
            await this.getIncomeProofs();
            this.closeFormPrompt();
        }
        catch (error) {
            this.warn(error);
            this.onError = true;
        }
        this.showLoading(false);
    },
    async doRemoveItem(){
      this.showLoading(true);
      try {
        let payload = {pp_income_proof_id: this.pp_proof_selected.id};
        await axios.post(`/api/v1/income_proofs/remove_income_proof`, payload);
        await this.getIncomeProofs();
      }
      catch (error) {
        this.warn(error);
      }
      this.showLoading(false);
    },
    hasError(val){
        return this.errors.has(val);
    },
    errorText(val){
        return this.errors.first(val);
    },
    isSuccess(val){
        let ff = this.fields[val];
        return (ff && ff.required && ff.valid);
    },
    currencyFormat(value, decimales = 2){
      if(!value) return 0;
      else return parseFloat(value).toFixed(decimales);
    },
    unCurrencyFormat(value) {
      if(!value) return 0;
      let str = value.toString();
      let val = str.replace(",", "");
      val = val.replace(",", "");
      val = val.replace(",", "");
      val = val.replace(",", "");
      val = val.replace(",", "");
      val = val.replace(",", "");
      return parseFloat(val.toString());
    },
    // #region documents
    async getSectionFields(){
      try {
        let sid = this.get_current_sid;
        let response = await axios.get(`/api/v1/forms/getSectionFields/${sid}`);
        this.sectionInfo = response.data.section;

        let response2 = await axios.get(`/storagev3/documents/section/${sid}`);
        this.docInfo = response2.data[0];
      }
      catch (e) {
      }
    },
    async onFinish(){
      this.isPopupActive = false;
    },
    async refreshProofs(){
      await this.getIncomeProofs();
    },
    setOnNewView(){
      this.formEditionDisabled = false;
      this.currentTitle = "Nuevo comprobante.";
      this.currentView = 0;
    },
    setOnEditView(){
      this.formEditionDisabled = false;
      this.currentTitle = "Editar comprobante.";
      this.currentView = 1;
    },
    setOnShowView(){
      this.formEditionDisabled = true;
      this.currentTitle = "Detalle de comprobante.";
      this.currentView = 1;
    },
    // #endregion
  },
};
</script>

<style>
</style>